import React from 'react';
import { Button } from 'flowbite-react';
import { useNavigate } from 'react-router-dom';

export default function About() {
    const navigate = useNavigate()
    const handleAbout = () => {
        navigate("/about");
    };

    return (
        <div id="about" className="relative bg-white overflow-hidden mt-4">
            <div className="max-w-7xl mx-auto">
                <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
                    <svg className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
                        fill="currentColor" viewBox="0 0 100 100" preserveAspectRatio="none" aria-hidden="true">
                        <polygon points="50,0 100,0 50,100 0,100"></polygon>
                    </svg>

                    <div className="pt-1"></div>

                    <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
                        <div className="sm:text-center lg:text-left">
                            <h2 className="my-6 text-2xl tracking-tight font-extrabold text-gray-900 sm:text-3xl md:text-4xl">
                                About Us
                            </h2>

                            <p className='pb-14'>
                                Chelcko Engineering Services Limited is committed to delivering excellence in Mechanical Engineering, Industrial Plant Installation, Process Engineering, Electrical Engineering, Labor Supply, and General Supply. With a dedicated team of experts, we leverage our extensive experience and technical proficiency to ensure the highest quality in every project we undertake. Whether it's designing innovative solutions, executing complex installations, or providing reliable labor and supply services, we prioritize customer satisfaction and strive to exceed expectations in every aspect of our work.
                            </p>

                            <Button
                                className="border-[#f0b90b] bg-custom_blue px-6 py-2.5 text-base font-medium text-white rounded-full outline-none transition-all duration-500 hover:-translate-y-1.5 hover:bg-blue-950 focus:outline-none focus:ring-0 focus:ring-offset-0"
                                onClick={handleAbout}
                            >
                                Browse More <span className="mdi mdi-play-circle ml-2 align-middle text-xl"></span>
                            </Button>
                        </div>
                    </main>
                </div>
            </div>
            <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 ">
                <img className="h-56 w-full object-cover object-top sm:h-72 md:h-80 lg:w-full lg:h-full" src="/image@2x.png" alt="" />
            </div>
        </div>
    );
}
