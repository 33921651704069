import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";



const HeroSection = () => {

    const navigate = useNavigate()
    const handleContact = () => {
        navigate("/contact");
    };

    return (
        <section
            className="lg:pb-40 lg:pt-52  pt-28 relative"
            id="home"
        >
            <div className="overflow-hidden">
                <img
                    className="absolute inset-0 h-full w-full object-cover"
                    src="/herosection.png"
                    alt=""
                />
                <div className='absolute inset-0 bg-gradient-to-r from-[#123026] to-[#000000] opacity-55'> </div>
            </div>
            <div className="container mx-auto md:px-14  px-6">
                <div className="flex justify-start">
                    <div className="lg:w-11/12 text-start relative">
                        <div className="space-y-8 mb-10">
                            {/* Home Page Title */}
                            <motion.h2
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, x: 0 }}
                                transition={{ duration: 0.6, delay: 0.3 }}
                                className="text-white text-xl md:text-4xl lg:text-5xl leading-tight  capitalize font-semibold md:font-bold"
                            >
                                Delivering Excellence in Mechanical Engineering, Plant Installations, and More
                            </motion.h2>
                            <motion.p
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, delay: 0.6 }}
                                className="text-white text-base md:text-lg  md:py-3"
                            >
                                Providing quality engineering solutions to empower Africa.

                            </motion.p>

                            {/* <motion.button
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.6, delay: 0.9 }}
                                type="button"
                                onClick={handleContact} className="inline-block px-1 py-2.5 md:px-20 text-base font-medium cursor-pointer select-none outline-none rounded-full transition-all duration-500 focus:outline-none focus:ring-0 focus:ring-offset-0 hover:-translate-y-1.5 bg-custom_blue text-white border-[#f0b90b] hover:bg-blue-700"
                            >

                                Contact Us Today                                <span className="mdi mdi-play-circle text-xl align-middle ml-2"></span>
                            </motion.button> */}
                        </div>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default HeroSection;