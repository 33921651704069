import React from 'react';

export default function Faqs() {
    // Dummy FAQs data
    const faqsData = [
        {
            question: "What engineering services does Chelcko Engineering offer?",
            answer: "Chelcko Engineering specializes in Mechanical Engineering, Industrial Plant Installation, Process Engineering, Electrical Engineering, Labor Supply, and General Supply."
        },
        {
            question: "How does Chelcko Engineering ensure quality in its projects?",
            answer: "At Chelcko Engineering, we are committed to providing professional and technical excellence. We prioritize safety, quality, and integrity in all our projects. Our team undergoes continuous training and development to maintain high standards."
        },
        {
            question: "Can I request a quotation for a project?",
            answer: "Yes, you can request a quotation for your project by contacting us via phone or email. Our team will work with you to understand your requirements and provide a tailored solution."
        },
        {
            question: "Does Chelcko Engineering offer maintenance services?",
            answer: "Yes, Chelcko Engineering provides maintenance services for various engineering installations. Our team of experts ensures that your equipment operates efficiently and reliably."
        },
        {
            question: "How do I contact Chelcko Engineering for support?",
            answer: "If you need assistance with our services or have any questions, you can reach out to our support team by phone at +256 705592898 or +254 712675550, or by email at info@chelckoengineering.com."
        }
    ];

    return (
        <div className="relative w-full bg-white px-6   mt-8 shadow-xl ring-1 ring-gray-900/5   sm:rounded-lg sm:px-10">
            <div className="mx-auto px-5 relative z-10">
                <div className="flex flex-col items-center">
                    <h2 className="mt-5 text-center text-3xl font-bold tracking-tight md:text-5xl text-gray-900">FAQ</h2>
                    <p className="mt-3 text-lg  md:text-xl text-neutral-500">Frequently asked questions</p>
                </div>
                <div className="mx-auto mt-8 grid max-w-full divide-y divide-neutral-200">
                    {faqsData.map((faq, index) => (
                        <div className="py-5" key={index}>
                            <details className="group">
                                <summary className="flex cursor-pointer list-none items-center justify-between font-medium">
                                    <span >{faq.question}</span>
                                    <span className="transition group-open:rotate-180">
                                        <svg fill="none" height="24" shapeRendering="geometricPrecision"
                                            stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"
                                            strokeWidth="1.5" viewBox="0 0 24 24" width="24">
                                            <path d="M6 9l6 6 6-6"></path>
                                        </svg>
                                    </span>
                                </summary>
                                <p className="group-open:animate-fadeIn mt-3  text-neutral-600">{faq.answer}</p>
                            </details>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
