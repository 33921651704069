import React from 'react'
import HeroSection from '../Components/Herosection'
import Faqs from '../Components/faqs'
import About from '../Components/About'

export default function LandingPage() {
    return (
        <div>
            <HeroSection />
            <About />
            <Faqs />
        </div>
    )
}
