import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from './Components/Layout'
import ContactUs from './Pages/ContactUs'
import LandingPage from './Pages/LandingPage'
import About from './Pages/About'
import Services from './Pages/Services'
export default function App() {
  return (
    <div className='h-screen w-full'>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path='contact' element={<ContactUs />} />
          <Route path='about' element={<About />} />
          <Route path='services' element={<Services />} />
        </Route>
      </Routes>
    </div>
  )
}