import React from "react";
import { motion } from "framer-motion";
import AboutComponent1 from "../Components/AboutComponent1";

const Abouut = () => {
    return (
        <>
            <section
                className="lg:pb-40 lg:pt-56 py-28 relative"
                id="about"
            >
                <div className="overflow-hidden">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="/about.jpg"
                        alt=""
                    />
                    <div className="absolute inset-0 bg-gradient-to-r from-gray-900 to-gray-700 opacity-60"></div>
                </div>
                <div className="container mx-auto px-6">
                    <div className="flex justify-center">
                        <div className="lg:w-full text-center relative">
                            <div className="space-y-8 mb-10">
                                {/* Home Page Title */}
                                <motion.h2
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.6, delay: 0.3 }}
                                    className="text-white text-4xl md:text-5xl lg:text-6xl leading-tight capitalize font-bold"
                                >
                                    Who we Are
                                </motion.h2>



                            </div>
                        </div>
                    </div>
                </div>


            </section>

            <AboutComponent1 />

        </>
    );
};

export default Abouut;