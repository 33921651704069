import React from "react";

export default function AboutComponent1() {
  return (
    <>
      <div class="max-w-5xl mx-auto text-[#333] font-[sans-serif] my-4">
        <div class="text-center max-w-4xl mx-auto">
          <h2 class="text-3xl font-extrabold text-center mb-4 capitalize">
            Company Overview
          </h2>
          <p class="text-base">
            <b>Chelcko Engineering Services Limited</b> is a registered company
            and has technical and professional personnel in diverse operational
            fields natural and social sciences. Usually, the work force in the
            company in the presence of any given activity bears both
            international and local components. This company is thus a fruit
            friendship, which portrays development interlocking front; technical
            performance as perception, which model out comfortable societies in
            reality, we work closely with our clients to accurately interpret
            their dreams/visions in drawings and bring them to the desired
            reality through construction solutions to meet their needs;
          </p>
        </div>
        <div class="mt-4">
          <div class="grid md:grid-cols-2 items-center  gap-x-16">
            <div>
              <img
                alt=""
                src="https://readymadeui.com/image-1.webp"
                class="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
              />
            </div>
            <div>
              <h3 class="text-2xl font-extrabold mb-4">Our Mission</h3>
              <p class="text-sm">
                Our Mission is to provide our shareholders and customers with
                value through the provisioning of Engineering services,
                Construction operation and management of infrastructure,
                industrial and power projects. We believe in building trust and
                confidence through our services by constantly exceeding
                expectations of our clients and bringing the highest value to
                each relationship that is developed.
              </p>
            </div>
            <div class="max-md:order-1">
              <h3 class="text-2xl font-extrabold mb-4 capitalize">
                Our Vision
              </h3>
              <p class="text-base">
                To be recognized as the leading engineering and Construction
                company for its work method, and human resources capabilities
              </p>
            </div>
            <div>
              <img
                alt=""
                src="https://readymadeui.com/contact.webp"
                class="w-full object-contain rounded-md shadow-[0_14px_40px_-11px_rgba(93,96,127,0.2)]"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="bg-gray-50">
        <div class="max-w-6xl mx-auto text-[#333]  font-[sans-serif]">
          <div class="text-center max-w-2xl mx-auto">
            <h2 class="text-3xl font-extrabold text-center mb-4">
              Our Core Values
            </h2>
            <p class="text-sm">
              At Chelcko Engineering Services Limited, our core values are the
              guiding principles that drive our operations and shape our
              interactions with stakeholders. They reflect our commitment to
              excellence, integrity, and safety in all aspects of our work. Our
              values include:
            </p>
          </div>
          <div class=" bg-gray-50 lg:p-10 p-6 rounded-md">
            <div class="grid md:grid-cols-2 items-center gap-16">
              <div>
                <ul class="space-y-4 mt-8">
                  <li class="flex items-center text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      class="mr-4 bg-blue-500 fill-white rounded-full p-[3px]"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000"
                      />
                    </svg>
                    Act with Honesty and integrity
                  </li>
                  <li class="flex items-center text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      class="mr-4 bg-blue-500 fill-white rounded-full p-[3px]"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000"
                      />
                    </svg>
                    Provide professional and technical excellence
                  </li>
                  <li class="flex items-center text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      class="mr-4 bg-blue-500 fill-white rounded-full p-[3px]"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000"
                      />
                    </svg>
                    Commitment to the safety and quality
                  </li>
                  <li class="flex items-center text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      class="mr-4 bg-blue-500 fill-white rounded-full p-[3px]"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000"
                      />
                    </svg>
                    Respect for cultural diversity in the context of business
                    overview
                  </li>
                  <li class="flex items-center text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      class="mr-4 bg-blue-500 fill-white rounded-full p-[3px]"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000"
                      />
                    </svg>
                    Human Resources development and knowledge building
                  </li>
                  <li class="flex items-center text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      class="mr-4 bg-blue-500 fill-white rounded-full p-[3px]"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000"
                      />
                    </svg>
                    Cultivate long term relationship with our clients
                  </li>
                </ul>
              </div>
              <img
                alt=""
                src="https://readymadeui.com/cardImg.webp"
                class="w-full object-contain rounded-md"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="relative font-[sans-serif] before:absolute before:w-full before:h-full before:inset-0 before:bg-black before:opacity-50 before:z-10">
        <img
          src="https://readymadeui.com/cardImg.webp"
          alt=""
          class="absolute inset-0 w-full h-full object-cover"
        />
        <div class="min-h-[300px] relative z-50 h-full max-w-6xl mx-auto flex flex-col justify-center items-center text-center text-white p-6">
          <h2 class="sm:text-4xl text-2xl font-bold mb-6">
            Our Commitment to Safety
          </h2>
          <p class="text-lg text-center text-gray-50">
            The company has a cream of staff to ensure efficient day-to-day
            operations. We remain and work hand-in-hand with all personnel in
            ensuring that we live in a GOOD and healthy world by observing the
            environment, and we firmly believe in achieving a zero-accident
            rate. We conduct safety inductions for our personnel and hold daily
            toolbox meetings and weekly safety meetings to maintain our
            zero-accident goal. Thinking safety, moving safety, and working
            safely are the principles we adhere to. This is the word from the
            Director. The staff of the company is technical by credentials, and
            they are accordingly deployed to areas of activity where they have
            trained, technical, and experienced competence.
          </p>
        </div>
      </div>

      <div class="bg-white sm:px-6 p-4 font-[sans-serif]">
        <div class="max-w-2xl mx-auto">
          <div>
            <h2 class="text-4xl font-extrabold text-gray-800 mb-4 ">
              Company Expertize
            </h2>
            <p class="text-gray-500 text-sm mt-4">
              The company expertise is fold; Engineering categorized. This
              categorization thus means that the company has experts and
              professional staff pertaining to each front.
            </p>
          </div>
          <hr class="my-10" />
          <div class="grid gap-16">
            <div>
              <h3 class="text-2xl font-bold text-gray-800">
                Thus The Chelcko Engineering Services Aims At Assuming Hope.
              </h3>
              <div class="mt-4">
                <p class="text-gray-500 text-sm">
                  Therefore, Chelcko Engineering Services Limited sets forth the
                  quest of unified approach to its activities that the company
                  can either handle simultaneously or singularly depending on
                  the client’s interest. At the moment, such a stance is focused
                  at serving Africa based especially at the local government and
                  private sector units so as to act for future expansions and
                  considerations.
                </p>
              </div>
            </div>
          </div>

          <hr class="my-10" />
          <div class="grid gap-16">
            <div>
              <h3 class="text-2xl font-bold text-gray-800">
                Mode Of Contractual Undertakings{" "}
              </h3>
              <div class="mt-4">
                <p class="text-gray-500 text-sm">
                  Chelko as a principled company has a humanitarian respectful
                  approach and believes to all the aspects concerning all the
                  undertakings in the contracts between the clients to the
                  company to company itself i.e.
                </p>
                <ul class="space-y-3 mt-6 px-8">
                  <li class="flex items-center text-sm text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      class="mr-4 bg-blue-500 fill-white rounded-full p-[3px]"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000"
                      />
                    </svg>
                    Respect for the terms and agreement.{" "}
                  </li>
                  <li class="flex items-center text-sm text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      class="mr-4 bg-blue-500 fill-white rounded-full p-[3px]"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000"
                      />
                    </svg>
                    Respect for the demands and interest of the agreeing parties
                    and individuals.{" "}
                  </li>
                  <li class="flex items-center text-sm text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      class="mr-4 bg-blue-500 fill-white rounded-full p-[3px]"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                        data-original="#000000"
                      />
                    </svg>
                    Amicable and cordial following of discussions paths to reach
                    a friendly, efficient and goal effecting agreement.{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
