import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
    const [collapsed, setCollapsed] = useState(true);

    const toggleCollapse = () => {
        setCollapsed(!collapsed);
    };
    const toggleClose = () => {
        setCollapsed(true)
    };

    const [isShow, setIsShow] = useState(false);



    useEffect(() => {
        window.addEventListener('scroll', function () {
            if (window.scrollY > 0.5) {
                console.log("show ");
                setIsShow(true)
            } else {
                console.log("hide");
                setIsShow(false)
            }
        });
    })


    return (
        <div className='fixed w-full z-50 shadow-lg'>

            <nav className={`self-stretch  bg-custom_yellow  flex-row items-start justify-between pt-[4px] pb-2 pr-[52px] pl-[30px] box-border max-w-full gap-[20px] w-auto h-auto text-left text-base text-gray-200 font-open-sans  mq800:pr-[26px] mq800:box-border mq1125:flex-wrap ${isShow ? "hidden" : "md:flex hidden"}`}>
                <div className="flex flex-row items-start justify-start gap-[44px] max-w-full w-auto [align-self:unset] h-auto [transform:rotate(0deg)]  hover:[align-self:unset] hover:h-auto hover:flex-row hover:gap-[44px] hover:items-start hover:justify-start hover:[transform:rotate(0deg)] hover:max-w-full mq800:flex-wrap mq800:gap-[44px_22px]">
                    <div className="flex flex-row items-start justify-start gap-[5px] w-auto [align-self:unset] h-auto [transform:rotate(0deg)] text-center hover:flex hover:w-auto hover:[align-self:unset] hover:h-auto hover:flex-row hover:gap-[5px] hover:items-start hover:justify-start hover:[transform:rotate(0deg)]">
                        <div className="flex flex-col items-start justify-start pt-[3.5px] px-0 pb-0 box-border w-auto [align-self:unset] h-auto gap-[0px] [transform:rotate(0deg)] hover:flex hover:w-auto hover:[align-self:unset] hover:h-auto hover:flex-col hover:gap-[0px] hover:items-start hover:justify-start hover:[transform:rotate(0deg)] hover:pt-[3.5px] hover:px-0 hover:pb-0 hover:box-border">
                            <img
                                className=" relative  shrink-0 flex gap-[0px] hover:flex hover:w-5 hover:h-5 hover:gap-[0px]"
                                loading="lazy"
                                alt=""
                                src="/basic.svg"
                            />
                        </div>
                        <b className="relative  leading-[170%] capitalize flex min-w-[100] whitespace-nowrap font-bold hover:font-bold hover:font-open-sans hover:text-base hover:leading-[170%] hover:text-center  hover:flex hover:min-w-[100] hover:whitespace-nowrap">
                            +256 705592898
                        </b>
                    </div>
                    <div className="flex flex-row items-start justify-start gap-[5px] w-auto [align-self:unset] h-auto [transform:rotate(0deg)] hover:flex hover:w-auto hover:[align-self:unset] hover:h-auto hover:flex-row hover:gap-[5px] hover:items-start hover:justify-start hover:[transform:rotate(0deg)]">
                        <div className="flex flex-col items-start justify-start pt-[3.5px] px-0 pb-0 box-border w-auto [align-self:unset] h-auto gap-[0px] [transform:rotate(0deg)] hover:flex hover:w-auto hover:[align-self:unset] hover:h-auto hover:flex-col hover:gap-[0px] hover:items-start hover:justify-start hover:[transform:rotate(0deg)] hover:pt-[3.5px] hover:px-0 hover:pb-0 hover:box-border">
                            <img
                                className="w-5 h-5 relative overflow-hidden shrink-0 flex gap-[0px] hover:flex hover:w-5 hover:h-5 hover:gap-[0px]"
                                alt=""
                                src="/basic-1.svg"
                            />
                        </div>
                        <b className="relative leading-[170%] capitalize flex min-w-[96] whitespace-nowrap font-bold hover:font-bold hover:font-open-sans hover:text-base hover:leading-[170%] hover:text-left hover:text-gray-200 hover:flex hover:min-w-[96] hover:whitespace-nowrap">
                            07:00 - 16:00
                        </b>
                    </div>
                    <div className="flex flex-row items-start justify-start gap-[5px] w-auto [align-self:unset] h-auto [transform:rotate(0deg)] hover:flex hover:w-auto hover:[align-self:unset] hover:h-auto hover:flex-row hover:gap-[5px] hover:items-start hover:justify-start hover:[transform:rotate(0deg)]">
                        <div className="flex flex-col items-start justify-start pt-[3.5px] px-0 pb-0 box-border w-auto [align-self:unset] h-auto gap-[0px] [transform:rotate(0deg)] hover:flex hover:w-auto hover:[align-self:unset] hover:h-auto hover:flex-col hover:gap-[0px] hover:items-start hover:justify-start hover:[transform:rotate(0deg)] hover:pt-[3.5px] hover:px-0 hover:pb-0 hover:box-border">
                            <img
                                className="w-5 h-5 relative overflow-hidden shrink-0 flex gap-[0px] hover:flex hover:w-5 hover:h-5 hover:gap-[0px]"
                                alt=""
                                src="/basic-2.svg"
                            />
                        </div>
                        <b className="relative leading-[170%] text-base capitalize flex font-bold hover:font-bold hover:font-open-sans hover:text-base hover:leading-[170%] hover:text-left hover:text-gray-200 hover:flex">
                            Jinja, Uganda
                        </b>
                    </div>
                </div>
                <div className="md:flex flex-row hidden items-start justify-start gap-[25.799999999999727px] w-auto [align-self:unset] h-auto [transform:rotate(0deg)] hover:flex hover:w-auto hover:[align-self:unset] hover:h-auto hover:flex-row hover:gap-[25.799999999999727px] hover:items-start hover:justify-start hover:[transform:rotate(0deg)]">
                    <img
                        className="h-5 w-[22.1px] relative object-cover min-h-[20] flex hover:flex hover:w-[22.1px] hover:h-5 hover:min-h-[20]"
                        loading="lazy"
                        alt=""
                        src="/image-2@2x.png"
                    />
                    <img
                        className="h-5 w-[20.3px] relative object-cover min-h-[20] flex hover:flex hover:w-[20.3px] hover:h-5 hover:min-h-[20]"
                        loading="lazy"
                        alt=""
                        src="/image-1@2x.png"
                    />
                    <img
                        className="h-5 w-[19.7px] relative object-cover min-h-[20] flex hover:flex hover:w-[19.7px] hover:h-5 hover:min-h-[20]"
                        loading="lazy"
                        alt=""
                        src="/image-3@2x.png"
                    />
                </div>
            </nav>
            <header className="md:py-1  px-4 sm:px-10 bg-white z-50 relative">
                <div className='max-w-7xl w-full mx-auto flex flex-wrap items-center gap-4'>
                    <div className='flex justify-between items-center w-full'>
                        <div className='flex '>

                            <Link to="/" aria-label="Go to homepage z-50" onClick={toggleClose}>

                                <img src="/logo.png" alt="" className='w-20 h-12 object-cover' />

                            </Link>
                            <Link to="/" className='block  hover:cursor-pointer'>
                                <p className='text-2xl font-bold'>Chelcko  </p>
                                <span className='font-medium text-sm'>Engineering Service</span>

                            </Link>

                        </div>
                        <div id="collapseMenu"
                            className={` lg:!block max-lg:fixed max-lg:before:fixed max-lg:before:bg-white max-lg:before:opacity-40 max-lg:before:inset-0  ${collapsed ? "hidden" : ""}`}>
                            <button id="toggleClose" type='button' className={`lg:hidden fixed top-2 right-4 z-[100] rounded-full bg-white text-4xl h-14 w-14 ${collapsed ? "" : ""}`} onClick={toggleCollapse}>
                                x
                            </button>

                            <ul
                                className='lg:!flex lg:ml-12 lg:space-x-6  max-lg:space-y-6 max-lg:fixed text-custom_blue max-lg:bg-white max-lg:w-1/2 max-lg:min-w-[300px] max-lg:top-0 max-lg:left-0 max-lg:p-4 max-lg:h-full max-lg:shadow-md max-lg:overflow-auto z-50'>
                                <li className='max-lg:border-b max-lg:pb-4 px-3 lg:hidden bg'>
                                    <Link to="/" aria-label='Go to homepage' onClick={toggleClose}><img src="/logo.png" alt="logo" className='w-40' />
                                    </Link>
                                </li>
                                <li className='max-lg:border-b max-lg:py-2 px-3'>
                                    <Link to='/'
                                        className='hover:text-custom_yellow decoration-custom_yellow active:underline focus:underline block font-bold transition-all' onClick={toggleClose}>Home</Link>
                                </li>
                                <li className='max-lg:border-b max-lg:py-2 px-3'><Link to='/about'
                                    className='hover:text-custom_yellow decoration-custom_yellow active:underline focus:underline block font-bold transition-all' onClick={toggleClose}>About</Link>
                                </li>
                                {/* <li className='max-lg:border-b max-lg:py-2 px-3 group relative'>
                                    <span
                                        className='hover:text-custom_yellow decoration-custom_yellow active:underline focus:underline block font-bold transition-all'>Services
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-3 fill-current inline ml-1.5" viewBox="0 0 24 24">
                                            <path fillRule="evenodd"
                                                d="M11.99997 18.1669a2.38 2.38 0 0 1-1.68266-.69733l-9.52-9.52a2.38 2.38 0 1 1 3.36532-3.36532l7.83734 7.83734 7.83734-7.83734a2.38 2.38 0 1 1 3.36532 3.36532l-9.52 9.52a2.38 2.38 0 0 1-1.68266.69734z"
                                                clipRule="evenodd" data-original="#000000" />
                                        </svg>
                                    </span>

                                    <ul
                                        className='absolute shadow-lg bg-white space-y-3 lg:top-5 max-lg:top-8 -left-0 min-w-[250px] z-50 max-h-0 overflow-hidden group-hover:opacity-100 group-hover:max-h-[700px] px-6 group-hover:pb-4 group-hover:pt-6 transition-all duration-500'>
                                        <li className='border-b py-2 '><Link to='/Services'
                                            className='hover:text-custom_yellow decoration-custom_yellow active:underline focus:underline font-bold block transition-all'>Services</Link></li>
                                        <li className='border-b py-2 '><Link to='/categories'
                                            className='hover:text-custom_yellow decoration-custom_yellow active:underline focus:underline font-bold block transition-all'>Engineering Category</Link></li>
                                    </ul>
                                </li> */}


                                <li className='max-lg:border-b max-lg:py-2 px-3'><Link to='/services'
                                    className='hover:text-custom_yellow decoration-custom_yellow active:underline focus:underline block font-bold transition-all' onClick={toggleClose}>Services</Link>
                                </li>

                                {/* <li className='max-lg:border-b max-lg:py-2 px-3'><Link to='/contact'
                                    className='hover:text-custom_yellow decoration-custom_yellow active:underline focus:underline block font-bold transition-all' onClick={toggleClose}>Contact</Link>
                                </li> */}

                            </ul>
                        </div>


                        <div className="flex ml-auto lg:hidden">
                            <button id="toggleOpen" type="button" className={`lg:hidden ml-7 ${collapsed ? "" : "hidden"}`} onClick={toggleCollapse} aria-label="Toggle Navigation">
                                <svg className="w-7 h-7" fill="#000" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path>
                                </svg>
                            </button>
                        </div>

                    </div>
                </div>
            </header>
        </div>
    );
}

export default Navbar;
