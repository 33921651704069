import React from 'react';
import { Outlet } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Navbar from './Navbar';
import Footer from './Footer';

const pageVariants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    y: -50,
    transition: {
      duration: 0.5,
      ease: 'easeInOut',
    },
  },
};

export default function Layout() {
  return (
    <div>
      <Navbar />
      <AnimatePresence mode='wait'>
        <motion.main
          key="main"
          variants={pageVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          className='z-20'
        >
          <Outlet />
        </motion.main>
      </AnimatePresence>
      <Footer />
    </div>
  );
}
