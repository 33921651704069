import React from "react";

// Dummy data for services
const servicesData = [
  {
    title: "Mechanical installations.",
    // description: 'Tailor our product to suit your needs.',
    features: ["Installation", "Design", "Commissioning"],
  },
  {
    title: "Plant installations",
    // description: 'Your data is protected by the latest security measures.',
    features: [
      "Structural fabrication and erection",
      "Insulation and Lagging.",
      "Pipe fabrication and Laying.",
      "Security",
    ],
  },
  {
    title: "Management Staff.",
    // description: 'These are well trained staff in safety related issues and observes safety  measure at any moment Environmental thus makes achieve our objection without time loss on accidents.',
    features: [
      "Managing Director",
      "Human Resource",
      "Engineering Manager",
      "Operations Manager",
      "Finance Manager",
    ],
  },
  {
    title: "Management",
    // description: 'We have well experienced work men in different fields that we supply to our clients as: -',
    features: ["Managers", "Engineers", "Supervisors", "Foremen", "Designers"],
  },

  {
    title: "Welders",
    // description: 'We have well experienced work men in different fields that we supply to our clients as: -',
    features: ["Corded TIG Welders", "Corded ARC Welders"],
  },
  {
    title: "Fitters",
    // description: 'We have well experienced work men in different fields that we supply to our clients as: -',
    features: [
      "Pipe Fitters",
      "General Fitters",
      "Sheet Metal Fitters",
      "Steel Erectors",
    ],
  },
  {
    title: "Plumbers and Electricians",
    // description: 'We have well experienced work men in different fields that we supply to our clients as: -',
    features: ["Plumbers", "Electricians High and Low Voltage"],
  },
  {
    title: "  Operators and Mechanics",
    // description: 'We have well experienced work men in different fields that we supply to our clients as: -',
    features: ["Plant Operators", "Plant Mechanics", "Machinists"],
  },
];

const dummyData = [
  {
    title: "Hydro Power Installation",
    image: "/image1.png",
  },
  {
    title: "Pump Installation, Fabrication & Piping",
    image: "/image2.png",
  },
  {
    title: "Water Plant Installation & Commissioning",
    image: "/image3.png",
  },
  {
    title: "Turbine Embedded Parts Erection–Draft Tube",
    image: "/image4.png",
  },
  {
    title: "Water Cooling Plant Installation $ Commission",
    image: "/image5.png",
  },
  {
    title: "Storage Tanks Fabrication.",
    image: "/image6.png",
  },
  {
    title: "Lagging & Pipe Installations",
    image: "/image7.png",
  },
  {
    title: "Erection Of Stay Ring & Pit LIners Artvin Hydro Power Station",
    image: "/image8.png",
  },
  {
    title: "Fabrication Of Cooling Pipes Artin Hydro Power Station",
    image: "/image9.png",
  },
];

export default function ServicesComponent() {
  return (
    <>
      <div className=" md:px-6">
        <div className="max-w-6xl  mx-auto font-sans text-[#333]">
          <div className="text-center max-w-2xl mx-auto">
            <h2 className="sm:text-3xl text-2xl font-extrabold">
              Work Easier Today
            </h2>
            <p className="text-sm text-gray-500 mt-6">
              Unlock a world of possibilities with our exclusive features.
              Explore how our unique offerings can transform your journey and
              empower you to achieve more.
            </p>
          </div>
          <div className="grid justify-center w-full lg:grid-cols-4 gap-12 mt-16 max-md:max-w-lg mx-auto">
            {servicesData.map((service, index) => (
              <div className="text-left" key={index}>
                <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                <p className="text-gray-500 text-sm">{service.description}</p>
                <ul className="space-y-3 mt-6">
                  {service.features.map((feature, i) => (
                    <li
                      key={i}
                      className="flex items-center text-sm text-gray-500"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17"
                        className="mr-4 bg-blue-500 fill-white rounded-full p-[3px]"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                          data-original="#000000"
                        />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Galleries section */}

      <div className=" bg-gradient-to-tl from-slate-100 to-gray-50 via-yellow-50 p-2">
        <div class="max-w-5xl mx-auto text-[#333] font-[sans-serif] my-4">
          <div class="text-center max-w-2xl mx-auto">
            <h2 class="text-3xl font-extrabold text-center mb-4">
              Our Exclusive Features
            </h2>
            <p class="text-sm">
              Unlock a world of possibilities with our exclusive features.
              Explore how our unique offerings can transform your journey and
              empower you to achieve more.
            </p>
          </div>
          <div class="mt-16">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3  gap-4">
              {dummyData.map((item, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg overflow-hidden shadow-md"
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-full h-48 object-cover"
                  />
                  <div className="p-4">
                    <h3 className="text-lg font-semibold">{item.title}</h3>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div class="relative font-[sans-serif] before:absolute before:w-full before:h-full before:inset-0 before:bg-black before:opacity-50 before:z-10">
        <img
          src="https://readymadeui.com/cardImg.webp"
          alt=""
          class="absolute inset-0 w-full h-full object-cover"
        />
        <div class="min-h-[300px] relative z-50 h-full max-w-6xl mx-auto flex flex-col justify-center items-center text-center text-white p-6">
          <figure className="max-w-screen-md mx-auto">
            <svg
              className="h-12 mx-auto mb-3 text-gray-50 dark:text-gray-600"
              viewBox="0 0 24 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                fill="currentColor"
              />
            </svg>
            <blockquote>
              <p className="text-2xl font-medium text-gray-50 dark:text-white">
                "We remain and work in hand to hand with all person in ensuring
                that we live in GOOD and Healthy world by observing Environment
                and we do believe on making a 0 (zero)accident. By this we do
                safety induction to our personnel and make daily tool box
                meetings and weekly safety meeting to attain our No 0 accident.
                Think safety, Move safety and Work safe."
              </p>
            </blockquote>
            <figcaption className="flex items-center justify-center mt-6 space-x-3">
              {/* <img className="w-6 h-6 rounded-full" src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/avatars/michael-gouch.png" alt="profile picture" /> */}
              <div className="flex items-center divide-x-2 divide-gray-500 dark:divide-gray-700">
                {/* <div className="pr-3 font-medium text-gray-900 dark:text-white">Micheal Gough</div> */}
                <div className="pl-3 text-sm font-light text-gray-500 dark:text-gray-400">
                  Our Director
                </div>
              </div>
            </figcaption>
          </figure>
        </div>
      </div>
    </>
  );
}
